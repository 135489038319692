"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.convertZoneId = void 0;
const convertZoneId = (zone) => {
    switch (zone) {
        case 'BZ-MC-01':
        case 'BZ-MC-02':
            return '몬스터 코트';
        case 'BZ-DC-01':
            return '다이나믹 코트';
        case 'BZ-ST-01':
            return '슛 투게더';
        case 'BZ-PK-01':
            return '프로키커';
        case 'BZ-AC-01':
            return '양궁';
        case 'EZ-JN-01':
            return '점핑 네스트';
        case 'EZ-DT-01':
            return '슈팅 네스트';
        case 'EZ-CS-01':
            return '클레이 사격';
        case 'EZ-BL-01':
            return '볼링';
        case 'EZ-SS-01':
            return '슛앤샷';
        case 'AZ-CZ-01':
        case 'AZ-CZ-02':
        case 'AZ-CZ-03':
        case 'AZ-CZ-04':
        case 'AZ-CZ-05':
            return '아트 클라이밍';
        case 'AZ-ZR-01':
            return '짚라인 & 로프코스';
        case 'AZ-MS-01':
            return '몬스터 슬라이드';
        case 'AZ-VS-01':
            return '버티컬 드롭 슬라이드';
        case 'AZ-JC-01':
            return '점핑 클라이밍';
        case 'AZ-FF-01':
            return '프리폴';
        case 'DZ-IR-01':
            return '이카로스 R';
        case 'DZ-TP-01':
        case 'DZ-TP-02':
            return '트램폴린';
        case 'DZ-DT-01':
            return '디지털 트램폴린';
        case 'DZ-BP-01':
            return '야구 피칭';
        case 'DZ-RB-01':
            return '랠리볼';
        case 'DZ-BB-01':
            return '야구 배팅';
        case 'DZ-TD-01':
            return '태권도';
        case 'HANAZON':
            return '아날로그 존';
        default:
            return zone;
    }
};
exports.convertZoneId = convertZoneId;
