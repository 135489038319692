"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TICKET_SALES_STATUS_MAP = void 0;
const enums_1 = require("../enums");
exports.TICKET_SALES_STATUS_MAP = {
    [enums_1.TicketSalesStatus.OnSale]: '판매중',
    [enums_1.TicketSalesStatus.Scheduled]: '판매예정',
    [enums_1.TicketSalesStatus.Closed]: '판매종료',
    [enums_1.TicketSalesStatus.Hidden]: '노출안함',
};
