"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TicketSalesStatus = void 0;
var TicketSalesStatus;
(function (TicketSalesStatus) {
    TicketSalesStatus["OnSale"] = "onSale";
    TicketSalesStatus["Scheduled"] = "scheduled";
    TicketSalesStatus["Closed"] = "closed";
    TicketSalesStatus["Hidden"] = "hidden";
})(TicketSalesStatus = exports.TicketSalesStatus || (exports.TicketSalesStatus = {}));
