"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.COUPON_LIFETIME_UNIT_MAP = void 0;
const enums_1 = require("../enums");
exports.COUPON_LIFETIME_UNIT_MAP = {
    [enums_1.CouponLifeTimeUnit.MilliSecond]: '밀리초',
    [enums_1.CouponLifeTimeUnit.Second]: '초',
    [enums_1.CouponLifeTimeUnit.Minute]: '분',
    [enums_1.CouponLifeTimeUnit.Hour]: '시간',
    [enums_1.CouponLifeTimeUnit.Day]: '일',
    [enums_1.CouponLifeTimeUnit.Month]: '개월',
    [enums_1.CouponLifeTimeUnit.Year]: '연/년',
};
