"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.COUPON_PROVIDER_MAP = exports.CouponProvider = void 0;
var CouponProvider;
(function (CouponProvider) {
    CouponProvider["Cosfo"] = "cosfo";
    CouponProvider["Yanolja"] = "yanolja";
})(CouponProvider = exports.CouponProvider || (exports.CouponProvider = {}));
exports.COUPON_PROVIDER_MAP = {
    [CouponProvider.Cosfo]: '코스포',
    [CouponProvider.Yanolja]: '야놀자',
};
