import { ClientError, GraphQLClient, RequestDocument } from 'graphql-request';
import { RequestInit } from 'graphql-request/dist/types.dom';
import { IResult } from 'types';

const abortController = typeof window !== 'undefined' ? new AbortController() : null;
let alertShown = false;

export const graphqlClient = new GraphQLClient(process.env.NEXT_PUBLIC_GRAPHQL_ENDPOINT!, {
  signal: abortController?.signal as RequestInit['signal'],
  requestMiddleware(request) {
    const token = globalThis?.localStorage?.getItem('token');
    return {
      ...request,
      headers: { ...request.headers, token: token ? token : '' },
    };
  },
  responseMiddleware(response) {
    if (response instanceof ClientError) {
      const { errors } = response.response;

      for (let err of errors!) {
        switch (err.extensions.code) {
          case 'UNAUTHENTICATED':
            if (!alertShown) {
              alert('새 기기로 로그인 했거나, 너무 오래된 로그인으로 인해 로그아웃 되었습니다. 다시 로그인해주세요.');
              alertShown = true;
            }
            abortController?.abort();
            globalThis?.localStorage?.removeItem('token');
            location.href = '/';
            break;
        }
      }
    }
  },
});

type WrapperGqlRequestOutput<T> =
  | {
      result: T;
      error: undefined;
    }
  | {
      result: null;
      error: string;
    };

interface WrapperGqlRequestProps<V> {
  gql: RequestDocument;
  variables: V extends Object ? V : undefined;
}

export const wrapperGqlRequest = async <R, V>({
  gql,
  variables,
}: WrapperGqlRequestProps<V>): Promise<WrapperGqlRequestOutput<R>> => {
  try {
    const { result } = await graphqlClient.request<IResult<R>>({
      document: gql,
      variables,
    });

    return {
      result,
      error: undefined,
    };
  } catch (error) {
    if (error instanceof ClientError && error.response.errors && error.response.errors.length > 0) {
      return {
        result: null,
        error: error.response.errors[0].message,
      };
    }

    return {
      result: null,
      error: error instanceof Error ? error.message : JSON.stringify(error),
    };
  }
};
