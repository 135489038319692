"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ko = void 0;
exports.ko = {
    auth: {
        loginEmail: {
            notFound: "'%{emailId}'은 가입하신 이메일 주소가 아닙니다.",
        },
    },
};
