"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.COMP_TICKET_HISTORY_MAP = exports.CompTicketHistory = void 0;
exports.CompTicketHistory = {
    HardwareError: 'hardware_error',
    APIError: 'api_error',
    InvalidOrder: 'invalid_order',
    AdditionalGroupEnter: 'additional_group_enter',
    Etc: 'etc',
};
exports.COMP_TICKET_HISTORY_MAP = {
    [exports.CompTicketHistory.HardwareError]: '키오스크(밴드) 오류',
    [exports.CompTicketHistory.APIError]: '외부연동(야놀자 등) 오류',
    [exports.CompTicketHistory.InvalidOrder]: '비정상적 오더',
    [exports.CompTicketHistory.AdditionalGroupEnter]: '단체 추가 입장',
    [exports.CompTicketHistory.Etc]: '기타',
};
