"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.sliceChar = exports.getCharLength = void 0;
function getCharLength(input) {
    let byte = 0;
    for (let i = 0; i < input.length; ++i) {
        input.charCodeAt(i) > 127 ? (byte += 2) : byte++;
    }
    return byte;
}
exports.getCharLength = getCharLength;
function sliceChar(input, length) {
    if (getCharLength(input) < length)
        return input;
    const char = [];
    for (let i = 0; i < input.length; ++i) {
        if (getCharLength(input.slice(0, i)) < length) {
            char.push(input[i]);
        }
        else {
            break;
        }
    }
    return `${char.join('')}...`;
}
exports.sliceChar = sliceChar;
