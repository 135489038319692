"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RESULT = void 0;
var RESULT;
(function (RESULT) {
    RESULT["SUCCESS"] = "\uC0AC\uC6A9\uAC00\uB2A5";
    RESULT["FAULT_1"] = "\uCFE0\uD3F0\uBC88\uD638 \uC624\uB958";
    RESULT["FAULT_2"] = "\uC774\uBBF8 \uC0AC\uC6A9\uB41C \uCFE0\uD3F0";
    RESULT["FAULT_3"] = "\uB2F9\uC9C0\uC810\uC5D0\uC11C \uC0AC\uC6A9 \uBD88\uAC00";
    RESULT["FAULT_4"] = "\uC0AC\uC6A9 \uC720\uD6A8\uAE30\uAC04 \uC624\uB958";
})(RESULT = exports.RESULT || (exports.RESULT = {}));
