"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.formatPhone = exports.isPhone = exports.isCellPhone = exports.normalizePhone = void 0;
const REGEX_RULES = [
    [/^(\d{2})(\d{2})(\d{2})(\d{2})$/, '$1$2-$3$4', '$1**-$3**'],
    [/^02(\d{3})(\d{4})$/, '02-$1-$2', '02-***-$2'],
    [/^02(\d{4})(\d{4})$/, '02-$1-$2', '02-****-$2'],
    [/^02(\d{4})(\d{4})$/, '02-$1-$2', '02-****-$2'],
    [/^(\d{3})(\d{3})(\d{4})$/, '$1-$2-$3', '$1-***-$3'],
    [/^(\d{3})(\d{4})(\d{4})$/, '$1-$2-$3', '$1-****-$3'],
];
const normalizePhone = (phone) => {
    let normalized = phone.replace(/[\n\t ]/g, '');
    normalized = normalized.replace(/^82/, '');
    normalized = normalized.replace('+82', '');
    if (/^[A-Z]\d*-\d+$/.test(normalized))
        normalized = normalized.replace(/^[A-Z]\d*-/, '');
    normalized = normalized.replace(/\D/g, '');
    normalized = normalized.replace(/^0/, '');
    if (!normalized) {
        return null;
    }
    return `0${normalized}`;
};
exports.normalizePhone = normalizePhone;
const isCellPhone = (cellPhone) => {
    if (cellPhone.startsWith('!')) {
        return true;
    }
    const normalized = (0, exports.normalizePhone)(cellPhone);
    if (!normalized)
        return false;
    return /^01\d{8,9}$/.test(normalized);
};
exports.isCellPhone = isCellPhone;
const isPhone = (phone) => {
    const normalized = (0, exports.normalizePhone)(phone);
    if (!normalized) {
        return false;
    }
    if (/^(02|0[3-9]{1}[0-9]{1}|)[0-9]{3,4}[0-9]{4}$/.test(normalized)) {
        return true;
    }
    if (/^(15|16|18)[0-9]{2}[0-9]{4}$/.test(normalized)) {
        return true;
    }
    return false;
};
exports.isPhone = isPhone;
const formatPhone = (phone, masked = false) => {
    if (!phone) {
        return null;
    }
    for (let i = 0; i < REGEX_RULES.length; i++) {
        const [rx, format, formatMasked] = REGEX_RULES[i];
        if (rx.test(phone)) {
            return phone.replace(rx, masked ? formatMasked : format);
        }
    }
    return phone;
};
exports.formatPhone = formatPhone;
