"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserGroupCategoryMap = exports.UserGroupCategory = void 0;
var UserGroupCategory;
(function (UserGroupCategory) {
    UserGroupCategory["School"] = "school";
    UserGroupCategory["Religion"] = "religion";
    UserGroupCategory["Enterprise"] = "enterprise";
    UserGroupCategory["Welfare"] = "welfare";
    UserGroupCategory["PublicInstitution"] = "publicinstitutions";
    UserGroupCategory["GeneralOrganization"] = "generalorganization";
    UserGroupCategory["Inbound"] = "inbound";
    UserGroupCategory["Academy"] = "academy";
    UserGroupCategory["EducationalTravel"] = "educationaltravel";
    UserGroupCategory["University"] = "university";
    UserGroupCategory["Etc"] = "etc";
})(UserGroupCategory = exports.UserGroupCategory || (exports.UserGroupCategory = {}));
exports.UserGroupCategoryMap = {
    [UserGroupCategory.School]: '학교단체',
    [UserGroupCategory.Religion]: '종교',
    [UserGroupCategory.Enterprise]: '기업',
    [UserGroupCategory.Welfare]: '복지',
    [UserGroupCategory.PublicInstitution]: '공공기관',
    [UserGroupCategory.GeneralOrganization]: '일반단체',
    [UserGroupCategory.Inbound]: '인바운드',
    [UserGroupCategory.Academy]: '학원',
    [UserGroupCategory.EducationalTravel]: '학교(수학여행)',
    [UserGroupCategory.University]: '대학생',
    [UserGroupCategory.Etc]: '기타',
};
