"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IssueLocation = void 0;
var IssueLocation;
(function (IssueLocation) {
    IssueLocation["TicketBox"] = "ticketBox";
    IssueLocation["Store"] = "store";
    IssueLocation["Kiosk"] = "kiosk";
    IssueLocation["Gate"] = "gate";
})(IssueLocation = exports.IssueLocation || (exports.IssueLocation = {}));
