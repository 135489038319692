import { AppProps } from 'next/app';
import Head from 'next/head';
import Script from 'next/script';
import { useRouter } from 'next/router';
import { DefaultSeo } from 'next-seo';
import React, { useEffect, useState } from 'react';
import { QueryClientProvider, QueryClient, QueryClientConfig } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { RecoilRoot } from 'recoil';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers';
import koLocale from 'dayjs/locale/ko';
import { CacheProvider, EmotionCache, Global } from '@emotion/react';
import { ThemeProvider } from '@mui/material/styles';
import { GlobalStyle, ThemeForSmobApp } from '../styles/theme';
import '../styles/globals.css';
import * as gtag from '../lib/gtag';
import * as gtm from '../lib/gtm';
import PcWrapper from '../components/Footer';
import DEFAULT_SEO from '../next-seo.config';
import koKR from '../constants/mui_locale';
import createEmotionCache from '../createEmotionCache';
import { ErrorBoundary } from '../components/ErrorBoundary';
import Custom500 from './500.page';
import 'react-notion-x/src/styles.css';
import Layout from '../components/Layout';
import useUnhandledRejectionError from '@/hooks/useUnhandledRejectionError';
import * as Sentry from '@sentry/nextjs';

interface MyAppProps extends AppProps {
  emotionCache?: EmotionCache;
}

const clientSideEmotionCache = createEmotionCache();
const queryDefaultOptions: QueryClientConfig = {
  defaultOptions: { queries: { useErrorBoundary: true }, mutations: { useErrorBoundary: true } },
};

export default function App({ Component, pageProps, emotionCache = clientSideEmotionCache }: MyAppProps) {
  const [queryClient] = useState(() => new QueryClient(queryDefaultOptions));
  const router = useRouter();

  useEffect(() => {
    const handleRouteChange = (url: URL) => {
      gtm.pageview(url);
      gtag.pageview(url);
    };
    router.events.on('routeChangeComplete', handleRouteChange);
    router.events.on('hashChangeComplete', handleRouteChange);
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
      router.events.off('hashChangeComplete', handleRouteChange);
    };
  }, [router.events]);

  useEffect(() => {
    const handleResize = () => {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    };
    window.addEventListener('resize', handleResize);
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => storePathValues, [router.asPath]);

  useUnhandledRejectionError(({ reason: error }) => {
    Sentry.captureException(error);
  });

  function storePathValues() {
    const storage = globalThis?.sessionStorage;
    if (!storage) return;

    const prevPath = storage.getItem('currentPath');
    storage.setItem('prevPath', prevPath!);
    storage.setItem('currentPath', globalThis.location.pathname);
  }

  return (
    <>
      <Head>
        <meta
          name="viewport"
          content="width=device-width, height=device-height, initial-scale=1.0, user-scalable=no, shrink-to-fit=yes"
        />
      </Head>
      <DefaultSeo {...DEFAULT_SEO} />
      <Script
        src="https://t1.kakaocdn.net/kakao_js_sdk/2.2.0/kakao.min.js"
        integrity="sha384-x+WG2i7pOR+oWb6O5GV5f1KN2Ko6N7PTGPS7UlasYWNxZMKQA63Cj/B2lbUmUfuC"
        crossOrigin="anonymous"
        onLoad={() => Kakao.init('b04eab256117e70aa6cbdda67edceba7')}
        onError={(e) => console.error(e)}
      />
      <Script src="https://cdn.bootpay.co.kr/js/bootpay-3.3.1.min.js" onError={(e) => console.error(e)} />
      {/* Google Tag Manager - Global base code */}
      <Script
        id="gtag-base"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','${gtm.GTM_ID}');
          `,
        }}
      />
      {/* Global Site Tag (gtag.js) - Google Analytics */}
      <Script strategy="afterInteractive" src={`https://www.googletagmanager.com/gtag/js?id=${gtag.GA_ID}`} />
      <Script
        id="gtag-init"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${gtag.GA_ID}', {
              page_path: window.location.pathname,
            });
          `,
        }}
      />
      <QueryClientProvider client={queryClient}>
        <CacheProvider value={emotionCache}>
          {/* 
            //@ts-ignore */}
          <RecoilRoot>
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={koLocale} localeText={koKR}>
              <ThemeProvider theme={ThemeForSmobApp}>
                <ErrorBoundary renderFallback={() => <Custom500 />}>
                  <Layout>
                    <Component {...pageProps} />
                  </Layout>
                </ErrorBoundary>
                <PcWrapper />
              </ThemeProvider>
              <Global styles={GlobalStyle} />
            </LocalizationProvider>
          </RecoilRoot>
        </CacheProvider>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </>
  );
}
