"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProductCategory = void 0;
var ProductCategory;
(function (ProductCategory) {
    ProductCategory["Ade"] = "ade";
    ProductCategory["Beverage"] = "beverage";
    ProductCategory["Coffee"] = "coffee";
    ProductCategory["Event"] = "event";
    ProductCategory["Etc"] = "etc";
    ProductCategory["Food"] = "food";
    ProductCategory["IceCream"] = "ice_cream";
    ProductCategory["LossFee"] = "loss_fee";
    ProductCategory["LunchBox"] = "lunchbox";
    ProductCategory["Product"] = "product";
    ProductCategory["Ramen"] = "ramen";
    ProductCategory["Rental"] = "rental";
    ProductCategory["SettlementAdjustment"] = "settlement_adjustment";
    ProductCategory["Smoothie"] = "smoothie";
    ProductCategory["Snack"] = "snack";
    ProductCategory["SportGoods"] = "sportgoods";
    ProductCategory["VendingMachine"] = "vending_machine";
    ProductCategory["BeverageVendingMachine"] = "beverage_vending_machine";
})(ProductCategory = exports.ProductCategory || (exports.ProductCategory = {}));
