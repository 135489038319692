"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CorpMembershipTicketStatus = void 0;
var CorpMembershipTicketStatus;
(function (CorpMembershipTicketStatus) {
    CorpMembershipTicketStatus["Used"] = "used";
    CorpMembershipTicketStatus["Reserved"] = "reserved";
    CorpMembershipTicketStatus["Canceled"] = "canceled";
    CorpMembershipTicketStatus["Unused"] = "unused";
})(CorpMembershipTicketStatus = exports.CorpMembershipTicketStatus || (exports.CorpMembershipTicketStatus = {}));
