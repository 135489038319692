"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getHoursAndMinutes = void 0;
const getHoursAndMinutes = (time) => {
    if (!time)
        throw new Error('적절하지 않는 시간 문자열 입니다.');
    const [hours, minutes] = time.split(':').map((t) => parseInt(t, 10));
    if (isNaN(hours) || isNaN(minutes))
        throw new Error('적절하지 않는 시간 문자열 입니다.');
    return { hours, minutes };
};
exports.getHoursAndMinutes = getHoursAndMinutes;
