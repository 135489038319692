import { ClientError } from 'graphql-request';

export default function isExpectedError(error: ClientError): boolean {
  const errors = error.response.errors || [];
  console.error(errors);

  switch (errors[0].extensions.code) {
    case 'UNAUTHENTICATED':
      return true;
    case 'EXPECTED_ERROR':
      return true;
    default:
      return false;
  }
}
