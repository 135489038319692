import { useState, useEffect } from 'react';

const useDeviceDetect = () => {
  const [isMobile, setMobile] = useState(false);
  const [isApp, setIsApp] = useState(false);
  const [isIOS, setIsIOS] = useState(false);

  const userAgent = typeof navigator === 'undefined' ? '' : navigator.userAgent;

  useEffect(() => {
    const mobile = Boolean(userAgent.match(/iPhone|iPad|iPod|Android|Mobi/i));
    setMobile(mobile);
  }, [userAgent]);

  useEffect(() => {
    const IOS = Boolean(userAgent.match(/iPhone|iPod|iPad/));
    setIsIOS(IOS);
  }, [userAgent]);

  useEffect(() => {
    const isOurApp = Boolean(userAgent.match(/sportsmonster/i));
    setIsApp(isOurApp);
  }, [userAgent]);

  return { isMobile, isIOS, isApp };
};

export default useDeviceDetect;
