import { useState, useEffect } from 'react';
import { WebViewEventType } from '../constants';

interface PostData {
  title?: string;
  message?: string;
  timer?: Date;
  fileName?: string;
  url?: string;
  [key: string]: any;
}

const useWebView = () => {
  const [response, setResponse] = useState<any>(null);
  const [type, setType] = useState<string>('');
  const [tag, setTag] = useState<any>(null);

  const webview = typeof window === 'undefined' ? null : window.BootpayRNWebView ?? window.ReactNativeWebView;

  const postMessage = ({ type, data }: { type: WebViewEventType; data?: PostData }) => {
    if (webview) webview.postMessage(JSON.stringify({ type, data }));
  };

  const RNListener = (e: any) => {
    if (['https://kauth.kakao.com', 'https://kapi.kakao.com'].includes(e.origin)) return;
    const { data, type } = JSON.parse(e.data);
    if ('tag' in data) {
      setTag(data);
    }
    setResponse(data);
    setType(type);
  };

  useEffect(() => {
    const iOSDevice = !!navigator.userAgent.match(/iPhone|iPod|iPad/);
    if (webview) {
      if (iOSDevice) window.addEventListener('message', RNListener);
      else document.addEventListener('message', RNListener);
    }
    return () => {
      if (webview) {
        if (iOSDevice) window.removeEventListener('message', RNListener);
        else document.removeEventListener('message', RNListener);
      }
    };
  }, [webview]);

  return { response, postMessage, type, tag, setTag };
};

export default useWebView;
