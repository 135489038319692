"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.client = exports.ApolloError = exports.gql = void 0;
const core_1 = require("@apollo/client/core");
Object.defineProperty(exports, "ApolloError", { enumerable: true, get: function () { return core_1.ApolloError; } });
Object.defineProperty(exports, "gql", { enumerable: true, get: function () { return core_1.gql; } });
const config_1 = require("./config");
exports.client = new core_1.ApolloClient({
    uri: config_1.Config.endpointInfo.graphql,
    cache: new core_1.InMemoryCache({}),
    defaultOptions: {
        query: {
            fetchPolicy: 'no-cache',
        },
    },
});
