"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CouponLifeTimeUnit = void 0;
var CouponLifeTimeUnit;
(function (CouponLifeTimeUnit) {
    CouponLifeTimeUnit["MilliSecond"] = "millisecond";
    CouponLifeTimeUnit["Second"] = "second";
    CouponLifeTimeUnit["Minute"] = "minute";
    CouponLifeTimeUnit["Hour"] = "hour";
    CouponLifeTimeUnit["Day"] = "day";
    CouponLifeTimeUnit["Month"] = "month";
    CouponLifeTimeUnit["Year"] = "year";
})(CouponLifeTimeUnit = exports.CouponLifeTimeUnit || (exports.CouponLifeTimeUnit = {}));
