"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.en = void 0;
exports.en = {
    auth: {
        loginEmail: {
            notFound: "'%{emailId}' is not a registered email address.",
        },
    },
};
