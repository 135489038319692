import React, { ReactNode } from 'react';
import styled from '@emotion/styled';
import { useChannelTalk } from '../../hooks/useChannelTalk';

interface Props {
  children: ReactNode;
}

const Layout = ({ children }: Props) => {
  useChannelTalk();

  return <Wrapper>{children}</Wrapper>;
};

export default Layout;

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;
