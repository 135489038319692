"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CouponType = void 0;
var CouponType;
(function (CouponType) {
    CouponType["Discount"] = "discount";
    CouponType["Membership"] = "membership";
    CouponType["PreSaled"] = "pre_saled";
    CouponType["Partnership"] = "partnership";
    CouponType["B2b"] = "b2b";
    CouponType["CorpMembership"] = "corp_membership";
})(CouponType = exports.CouponType || (exports.CouponType = {}));
