"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PaymentTypemap = exports.PaymentType = void 0;
var PaymentType;
(function (PaymentType) {
    PaymentType["CreditCard"] = "credit_card";
    PaymentType["Cash"] = "cash";
    PaymentType["GiftCard"] = "gift_card";
    PaymentType["HandWritten"] = "hand_written";
    PaymentType["KakaoPay"] = "kakaopay";
    PaymentType["WireTransfer"] = "wire_transfer";
    PaymentType["PayLater"] = "pay_later";
    PaymentType["NaverPay"] = "npay";
    PaymentType["Coupon"] = "coupon";
    PaymentType["Comp"] = "comp";
})(PaymentType = exports.PaymentType || (exports.PaymentType = {}));
exports.PaymentTypemap = {
    [PaymentType.CreditCard]: '신용카드',
    [PaymentType.Cash]: '현금',
    [PaymentType.GiftCard]: '상품권',
    [PaymentType.HandWritten]: '신용카드 수기',
    [PaymentType.KakaoPay]: '카카오페이',
    [PaymentType.PayLater]: '나중에결제',
    [PaymentType.NaverPay]: '네이버페이',
    [PaymentType.Coupon]: '쿠폰',
    [PaymentType.Comp]: '무상',
    [PaymentType.WireTransfer]: '계좌이체',
};
