"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RESERVATION_STATUS_MAP = exports.OrderStatusMap = exports.OrderStatus = void 0;
var OrderStatus;
(function (OrderStatus) {
    OrderStatus["WaitForPayment"] = "waitforpayment";
    OrderStatus["Paid"] = "paid";
    OrderStatus["Cancelled"] = "cancelled";
    OrderStatus["Incompleted"] = "incompleted";
    OrderStatus["DevLock"] = "dev_lock";
})(OrderStatus = exports.OrderStatus || (exports.OrderStatus = {}));
exports.OrderStatusMap = {
    [OrderStatus.WaitForPayment]: '결제 대기',
    [OrderStatus.Paid]: '결제 완료',
    [OrderStatus.Cancelled]: '취소/환불',
    [OrderStatus.Incompleted]: '미정산',
    [OrderStatus.DevLock]: '관리자 확인중',
};
exports.RESERVATION_STATUS_MAP = {
    [OrderStatus.WaitForPayment]: '예약 완료',
    [OrderStatus.Paid]: '결제 완료',
    [OrderStatus.Cancelled]: '예약 취소',
    [OrderStatus.DevLock]: '관리자 확인중',
    [OrderStatus.Incompleted]: '미정산',
};
