"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ADMINISTRATOR_STATUS_MAP = exports.AdministratorStatus = void 0;
var AdministratorStatus;
(function (AdministratorStatus) {
    AdministratorStatus["Pending"] = "pending";
    AdministratorStatus["Active"] = "active";
    AdministratorStatus["Resigned"] = "resigned";
})(AdministratorStatus = exports.AdministratorStatus || (exports.AdministratorStatus = {}));
exports.ADMINISTRATOR_STATUS_MAP = {
    [AdministratorStatus.Pending]: '대기',
    [AdministratorStatus.Active]: '정상',
    [AdministratorStatus.Resigned]: '탈퇴',
};
