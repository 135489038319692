"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserCouponStatus = void 0;
var UserCouponStatus;
(function (UserCouponStatus) {
    UserCouponStatus["Expired"] = "expired";
    UserCouponStatus["Paid"] = "paid";
    UserCouponStatus["Used"] = "used";
    UserCouponStatus["WaitForPayment"] = "waitforpayment";
    UserCouponStatus["Cancelled"] = "cancelled";
})(UserCouponStatus = exports.UserCouponStatus || (exports.UserCouponStatus = {}));
