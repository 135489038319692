"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserProductStatus = void 0;
var UserProductStatus;
(function (UserProductStatus) {
    UserProductStatus["Cancelled"] = "cancelled";
    UserProductStatus["Paid"] = "paid";
    UserProductStatus["Used"] = "used";
    UserProductStatus["WaitForPayment"] = "waitforpayment";
})(UserProductStatus = exports.UserProductStatus || (exports.UserProductStatus = {}));
