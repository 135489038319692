"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserStatus = void 0;
var UserStatus;
(function (UserStatus) {
    UserStatus["Banned"] = "banned";
    UserStatus["Active"] = "active";
    UserStatus["Inactive"] = "inactive";
    UserStatus["Withdrawal"] = "withdrawal";
})(UserStatus = exports.UserStatus || (exports.UserStatus = {}));
