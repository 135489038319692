"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Config = void 0;
const env = (process && process.env) || {};
class Config {
}
exports.Config = Config;
Config.debug = false;
const { CDN_BASE_URL, CDN_S3, MONGODB_URI, REDIS_HOST, REDIS_PORT, GRAPHQL_ENDPOINT_URL, DEBUG, SERVER_BIND_HOST, SERVER_BIND_PORT, } = env;
Config.cdn = {
    baseUrl: CDN_BASE_URL || 'https://swingby-cdn.s3.ap-northeast-2.amazonaws.com',
    s3: CDN_S3 || 'staging-cdn.swingby.us',
};
Config.mongo = {
    uri: MONGODB_URI || 'mongodb://127.0.0.1:27017/sportsmonster',
};
Config.redis = {
    host: REDIS_HOST || '127.0.0.1',
    port: Number(REDIS_PORT || 6379),
};
Config.server = {
    host: SERVER_BIND_HOST || '0.0.0.0',
    port: Number(SERVER_BIND_PORT || 7001),
};
Config.endpointInfo = {
    graphql: GRAPHQL_ENDPOINT_URL || 'http://127.0.0.1:7001/graphql',
};
Config.debug = DEBUG === 'true';
