"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProductEtcCategory = void 0;
var ProductEtcCategory;
(function (ProductEtcCategory) {
    ProductEtcCategory["Product"] = "product";
    ProductEtcCategory["SportGoods"] = "sportgoods";
    ProductEtcCategory["LossFee"] = "loss_fee";
    ProductEtcCategory["Rental"] = "rental";
    ProductEtcCategory["SettlementAdjustment"] = "settlement_adjustment";
    ProductEtcCategory["VendingMachine"] = "vending_machine";
    ProductEtcCategory["Etc"] = "etc";
})(ProductEtcCategory = exports.ProductEtcCategory || (exports.ProductEtcCategory = {}));
