import Image from 'next/image';
import styled from '@emotion/styled';
import { Color } from '../constants';
import Typography from '@mui/material/Typography';

export default function Custom500() {
  return (
    <>
      <Image src={'/500_cover.webp'} alt="500_cover" layout="fill" objectFit="cover" />
      <Content>
        <div>
          <Image src={'/500_text.svg'} alt="500" width={230} height={120} />
        </div>
        <div className="desc">
          <Typography variant="subtitle1">열심히 문제 해결 중입니다.</Typography>
          <Typography variant="subtitle1">잠시 후에 다시 접속해 주세요.</Typography>
        </div>
      </Content>
    </>
  );
}

const Content = styled.section`
  position: absolute;
  top: 70%;
  right: 0;
  left: 0;
  color: ${Color.background};

  div {
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .desc {
    margin: 32px 0 0;
  }
`;
