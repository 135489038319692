"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WORK_LOCATION_MAP = exports.WorkLocation = void 0;
var WorkLocation;
(function (WorkLocation) {
    WorkLocation["HeadOffice"] = "head_office";
    WorkLocation["Park"] = "park";
})(WorkLocation = exports.WorkLocation || (exports.WorkLocation = {}));
exports.WORK_LOCATION_MAP = {
    [WorkLocation.HeadOffice]: '본사',
    [WorkLocation.Park]: '지점',
};
