"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PaymentStatusMap = exports.PaymentStatus = void 0;
var PaymentStatus;
(function (PaymentStatus) {
    PaymentStatus["Cancelled"] = "cancelled";
    PaymentStatus["Paid"] = "paid";
    PaymentStatus["WaitForPayment"] = "waitforpayment";
})(PaymentStatus = exports.PaymentStatus || (exports.PaymentStatus = {}));
exports.PaymentStatusMap = {
    [PaymentStatus.Cancelled]: '취소',
    [PaymentStatus.Paid]: '결제 완료',
    [PaymentStatus.WaitForPayment]: '결제 진행중',
};
