"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProductFnbCategory = void 0;
var ProductFnbCategory;
(function (ProductFnbCategory) {
    ProductFnbCategory["Ade"] = "ade";
    ProductFnbCategory["Beverage"] = "beverage";
    ProductFnbCategory["Coffee"] = "coffee";
    ProductFnbCategory["Event"] = "event";
    ProductFnbCategory["Food"] = "food";
    ProductFnbCategory["IceCream"] = "ice_cream";
    ProductFnbCategory["LunchBox"] = "lunchbox";
    ProductFnbCategory["Ramen"] = "ramen";
    ProductFnbCategory["Smoothie"] = "smoothie";
    ProductFnbCategory["Snack"] = "snack";
    ProductFnbCategory["BeverageVendingMachine"] = "beverage_vending_machine";
})(ProductFnbCategory = exports.ProductFnbCategory || (exports.ProductFnbCategory = {}));
