import { useState } from 'react';
import ChannelService from '../lib/channelTalk';
import useUser from '../pages/users/hooks/useUser';
import useMounted from './useMounted';
import { useRouter } from 'next/router';
import { useUpdateEffect } from 'react-use';

const disabledPaths = ['/branch', '/band', '/band/qr', '/play/walkthrough'];

export const useChannelTalk = () => {
  const [channelService, setChannelService] = useState<ChannelService | null>(null);

  const isMounted = useMounted();
  const { user } = useUser();
  const { pathname } = useRouter();

  const handleToggleChannelButton = () => {
    if (!channelService) return;
    if (disabledPaths.includes(pathname)) channelService.hideChannelButton();
    else channelService.showChannelButton();
  };

  useUpdateEffect(() => {
    if (!channelService && isMounted) {
      const channelTalkInstance = new ChannelService();
      channelTalkInstance.loadScript();
      setChannelService(() => channelTalkInstance);
    }
  }, [channelService, isMounted]);

  useUpdateEffect(() => {
    if (!channelService) return;

    if (user) {
      const memberId = user.id;

      channelService.boot({
        pluginKey: process.env.NEXT_PUBLIC_CHANNEL_TALK_PLUGIN_KEY!,
        memberId,
        profile: {
          name: user.nick || null,
          email: user.email || null,
          mobileNumber: user.cellPhone || null,
        },
      });

      channelService.updateUser({ tags: ['회원'] });
    } else {
      channelService.boot({
        pluginKey: process.env.NEXT_PUBLIC_CHANNEL_TALK_PLUGIN_KEY!,
      });

      channelService.updateUser({ tags: ['비회원'] });
    }

    handleToggleChannelButton();

    return () => {
      channelService.shutdown();
    };
  }, [channelService, user?.id]);

  // * 지정된 path에서는 채널톡 버튼을 숨김
  useUpdateEffect(() => {
    handleToggleChannelButton();
  }, [pathname]);
};
