"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProductSalesStatus = void 0;
var ProductSalesStatus;
(function (ProductSalesStatus) {
    ProductSalesStatus["OnSale"] = "onSale";
    ProductSalesStatus["Scheduled"] = "scheduled";
    ProductSalesStatus["Closed"] = "closed";
    ProductSalesStatus["Hidden"] = "hidden";
})(ProductSalesStatus = exports.ProductSalesStatus || (exports.ProductSalesStatus = {}));
