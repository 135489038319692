"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ReportType = void 0;
var ReportType;
(function (ReportType) {
    ReportType["salesByItemPerDate"] = "byItem";
    ReportType["salesByPlacePerDate"] = "byPlace";
    ReportType["paymentByItemPerDate"] = "paymentByItemPerDate";
    ReportType["customerPriceByItemPerDate"] = "customerPrice";
    ReportType["userCountAgeGroupPerDate"] = "ageGroupPerDate";
    ReportType["userCountDetailPerDate"] = "detailPerDate";
    ReportType["userCountAgeGroupPerHours"] = "ageGroupPerHours";
    ReportType["DashboardUserCount"] = "userCount";
    ReportType["DashboardGraph"] = "graph";
    ReportType["userCountDetailPerHours"] = "detailPerHours";
    ReportType["DashboardTicketEtc"] = "ticketEtc";
    ReportType["DashboardTicketOthers"] = "ticketOthers";
    ReportType["DashboardTicket"] = "ticket";
})(ReportType = exports.ReportType || (exports.ReportType = {}));
