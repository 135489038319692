"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SalesTransactionCategory = void 0;
var SalesTransactionCategory;
(function (SalesTransactionCategory) {
    SalesTransactionCategory["Ticket"] = "ticket";
    SalesTransactionCategory["FnB"] = "fnb";
    SalesTransactionCategory["Etc"] = "etc";
    SalesTransactionCategory["SeasonPass"] = "membership";
    SalesTransactionCategory["PreSale"] = "presale";
    SalesTransactionCategory["Partnership"] = "partnership";
    SalesTransactionCategory["ExitFee"] = "exit_fee";
    SalesTransactionCategory["B2b"] = "b2b";
    SalesTransactionCategory["CorpMembership"] = "corp_membership";
})(SalesTransactionCategory = exports.SalesTransactionCategory || (exports.SalesTransactionCategory = {}));
