"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserTicketStatus = void 0;
var UserTicketStatus;
(function (UserTicketStatus) {
    UserTicketStatus["Cancelled"] = "cancelled";
    UserTicketStatus["Paid"] = "paid";
    UserTicketStatus["Used"] = "used";
    UserTicketStatus["WaitForPayment"] = "waitforpayment";
})(UserTicketStatus = exports.UserTicketStatus || (exports.UserTicketStatus = {}));
