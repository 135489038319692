"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.OrderSaledAt = void 0;
var OrderSaledAt;
(function (OrderSaledAt) {
    OrderSaledAt["TicketBox"] = "ticket_box";
    OrderSaledAt["Online"] = "online";
    OrderSaledAt["Kiosk"] = "kiosk";
    OrderSaledAt["Marketing"] = "marketing";
    OrderSaledAt["B2b"] = "b2b";
})(OrderSaledAt = exports.OrderSaledAt || (exports.OrderSaledAt = {}));
