"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.calendarMap = void 0;
exports.calendarMap = {
    High: 'high',
    Low: 'low',
    Selected: 'selected',
    Dayoff: 'dayoff',
    Closed: 'closed',
    PastDay: 'past',
    RangeOutDay: 'rangeOut',
    Today: 'today',
    Open: 'open',
};
