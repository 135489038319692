"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Product = exports.PackageItem = exports.ProductMetaData = void 0;
class ProductMetaData {
}
exports.ProductMetaData = ProductMetaData;
class PackageItem {
}
exports.PackageItem = PackageItem;
class Product {
}
exports.Product = Product;
