"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ADMINISTRATOR_ROLE_MAP = exports.AdministratorRole = void 0;
var AdministratorRole;
(function (AdministratorRole) {
    AdministratorRole["SuperAdmin"] = "super_admin";
    AdministratorRole["Admin"] = "admin";
    AdministratorRole["Employee"] = "employee";
    AdministratorRole["Contractor"] = "contractor";
})(AdministratorRole = exports.AdministratorRole || (exports.AdministratorRole = {}));
exports.ADMINISTRATOR_ROLE_MAP = {
    [AdministratorRole.SuperAdmin]: '최고관리자',
    [AdministratorRole.Admin]: '총괄관리자',
    [AdministratorRole.Employee]: '사원',
    [AdministratorRole.Contractor]: '계약직',
};
