"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.formatWon = void 0;
function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}
const formatWon = (amount, withPostfix = false) => {
    if (typeof amount !== 'number') {
        return amount;
    }
    if (amount === null || amount === undefined) {
        return 'n/a';
    }
    let result = numberWithCommas(amount);
    if (withPostfix) {
        result = `${result}원`;
    }
    return result;
};
exports.formatWon = formatWon;
