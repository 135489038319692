"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AdminRole = void 0;
var AdminRole;
(function (AdminRole) {
    AdminRole["Master"] = "master";
    AdminRole["Admin"] = "admin";
    AdminRole["Viewer"] = "viewer";
    AdminRole["Accounting"] = "accounting";
    AdminRole["GroupManager"] = "group_manager";
    AdminRole["CustomerManager"] = "customer_manager";
    AdminRole["SalesManager"] = "sales_manager";
    AdminRole["ProductManager"] = "product_manager";
    AdminRole["Marketing"] = "marketing";
    AdminRole["ParkManager"] = "park_manager";
    AdminRole["GeneralManager"] = "general_manager";
    AdminRole["POS"] = "pos";
    AdminRole["Group1"] = "group1";
    AdminRole["Group2"] = "group2";
    AdminRole["Group3"] = "group3";
})(AdminRole = exports.AdminRole || (exports.AdminRole = {}));
