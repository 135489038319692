"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.calculatePrice = void 0;
const __1 = require("..");
const calculatePrice = (target, directDiscountCoupon, userCoupons, discountPoint = 0, additionalPrice = 0, optionPrice = 0) => {
    if (!target || target.price === undefined) {
        throw new Error('상품의 가격을 가져오던 중 문제가 발생했습니다.');
    }
    const coupons = userCoupons.map((x) => x.coupon).filter((x) => x);
    if (directDiscountCoupon) {
        coupons.push(directDiscountCoupon);
    }
    coupons.forEach((coupon) => {
        if (!coupon || !(coupon === null || coupon === void 0 ? void 0 : coupon.discountMethod) || !(coupon === null || coupon === void 0 ? void 0 : coupon.value)) {
            throw new Error('쿠폰의 정의를 가져오던 중 문제가 발생했습니다.');
        }
    });
    const point = discountPoint;
    const couponByAmount = coupons
        .filter((x) => x.discountMethod === __1.CouponDiscountMethod.Amount)
        .reduce((acc, coupon) => acc + coupon.value, 0);
    const couponByRateMultiplied = coupons
        .filter((x) => x.discountMethod === __1.CouponDiscountMethod.Rate)
        .reduce((acc, coupon) => (acc * (100 - coupon.value)) / 100, 1);
    const couponByRate = (target.price + optionPrice - couponByAmount) *
        (Math.round((1 - couponByRateMultiplied) * 100) / 100);
    const discountAmount = Math.floor((point + couponByAmount + couponByRate) / 100) * 100;
    const totalPrice = Math.min(target.price + optionPrice + additionalPrice, Math.max(target.price + optionPrice + additionalPrice - discountAmount, 0, ...coupons.map((i) => i.minimumPayAmount || 0)));
    return totalPrice;
};
exports.calculatePrice = calculatePrice;
