"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserType = void 0;
var UserType;
(function (UserType) {
    UserType["Agent"] = "agent";
    UserType["Root"] = "root";
    UserType["User"] = "user";
    UserType["Group"] = "group";
    UserType["Pass"] = "pass";
})(UserType = exports.UserType || (exports.UserType = {}));
