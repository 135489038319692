export const SPORTSMONSTER_KAKAO_URL = 'https://bit.ly/3S3C8N6';
export const KAKAO_CHAT_URL = 'https://bit.ly/41JtVAW';
export const KAKAO_CHAT_URL_2 = 'https://bit.ly/4bG401i';
export const NAVER_TALK_URL = 'https://bit.ly/3SBQBgU';
export const SMOB_INSTAGRAM_URL = 'https://www.instagram.com/smob___/';
export const SMOB_YOUTUBE_URL = 'https://www.youtube.com/c/%EC%8A%A4%ED%8F%AC%EC%B8%A0%EB%AA%AC%EC%8A%A4%ED%84%B0';
export const STORE_URL = {
  IOS: 'https://apps.apple.com/kr/app/%EC%8A%A4%ED%8F%AC%EC%B8%A0%EB%AA%AC%EC%8A%A4%ED%84%B0/id1597689701',
  AOS: 'https://play.google.com/store/apps/details?id=kr.co.wepeak.sportsmonster',
};
