import { useQuery, useQueryClient } from '@tanstack/react-query';
import { User } from 'swingby-shared/dist/interfaces';
import { IResult, DocumentType } from '../../../types';
import { graphqlClient } from '../../../lib/graphql';
import { RequestInit } from 'graphql-request/dist/types.dom';
import { gql } from 'graphql-request';
import { UserStatus } from 'swingby-shared';
import { useRouter } from 'next/router';

interface UseUser {
  user: DocumentType<User> | null;
  updateUser: (newUser: User) => void;
  clearUser: () => void;
  isFetched: boolean;
}

export const QUERY_KEY_USER = ['user'];

const queryUser = async (signal?: AbortSignal): Promise<DocumentType<User> | null> => {
  const token = globalThis?.localStorage?.getItem('token');
  if (!token) return null;

  const { result: user } = await graphqlClient.request<IResult<DocumentType<User>>>({
    document: QUERY_ME,
    signal: signal as NonNullable<RequestInit['signal']>,
  });

  return user;
};

export const useUser = (): UseUser => {
  const queryClient = useQueryClient();
  const router = useRouter();

  const { data: user, isFetched } = useQuery({
    queryKey: QUERY_KEY_USER,
    queryFn: ({ signal }) => queryUser(signal),
    initialData: null,
    onSuccess: (user) => {
      // * 휴면 회원이 해제 하지 않고 다른페이지 이동 시 로그아웃 처리
      if (user && user.status === UserStatus.Inactive && router.pathname !== '/users/inactive') {
        clearUser();
      }
    },
  });

  const updateUser = (newUser: User): void => {
    queryClient.setQueryData(QUERY_KEY_USER, newUser);
  };

  const clearUser = (): void => {
    queryClient.setQueryData(QUERY_KEY_USER, null);
    queryClient.removeQueries(QUERY_KEY_USER);
    queryClient.clear();
    localStorage.removeItem('token');
  };

  return {
    user,
    updateUser,
    clearUser,
    isFetched,
  };
};

export default useUser;

export const FRAGMENT_USER = gql`
  fragment userData on User {
    id
    uid
    status
    cellPhone
    name
    nick
    password
    email
    type
    kakaoId
    appleId
    emailId
    birth
    gender
    profileImageUrl
    metaData {
      marketingTerm
      marketingTermTimeStamp
      signUpPath
    }
  }
`;

const QUERY_ME = gql`
  query me {
    result: me {
      ...userData
    }
  }
  ${FRAGMENT_USER}
`;
