"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EphemeralCodeStatus = void 0;
var EphemeralCodeStatus;
(function (EphemeralCodeStatus) {
    EphemeralCodeStatus["Available"] = "available";
    EphemeralCodeStatus["Used"] = "used";
    EphemeralCodeStatus["Cancelled"] = "cancelled";
    EphemeralCodeStatus["Expired"] = "expired";
})(EphemeralCodeStatus = exports.EphemeralCodeStatus || (exports.EphemeralCodeStatus = {}));
