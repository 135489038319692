"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DEPARTMENT_MAP = exports.Department = void 0;
var Department;
(function (Department) {
    Department["Design"] = "design";
    Department["Platform"] = "platform";
    Department["ProjectManagement"] = "project_management";
    Department["SpacePlanning"] = "space_planning";
    Department["ContentsPlanning"] = "contents_planning";
    Department["CustomerService"] = "customer_service";
    Department["Growth"] = "growth";
    Department["SMQC"] = "smqc";
    Department["ManagementSupport"] = "management_support";
    Department["FinancialAccounting"] = "financial_accounting";
})(Department = exports.Department || (exports.Department = {}));
exports.DEPARTMENT_MAP = {
    [Department.Design]: '디자인팀',
    [Department.Platform]: '플랫폼팀',
    [Department.ProjectManagement]: 'PM팀',
    [Department.SpacePlanning]: '스페이스플래닝팀',
    [Department.ContentsPlanning]: '콘텐츠플래닝팀',
    [Department.CustomerService]: '고객경험팀',
    [Department.Growth]: '그로스팀',
    [Department.SMQC]: 'SMQC팀',
    [Department.ManagementSupport]: '경영지원팀',
    [Department.FinancialAccounting]: '재무회계팀',
};
