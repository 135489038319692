"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ZoneType = void 0;
var ZoneType;
(function (ZoneType) {
    ZoneType["Basic"] = "basic";
    ZoneType["Exciting"] = "exciting";
    ZoneType["Adventure"] = "adventure";
    ZoneType["Digital"] = "digital";
})(ZoneType = exports.ZoneType || (exports.ZoneType = {}));
